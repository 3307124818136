import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import Image from 'gatsby-plugin-sanity-image';

export default function ArtistScroller({ className, title, artists, programs }) {
  return (
    <div
      className={className}
    >
      <div
        className="type--70"
        css={css`
          padding: 0 var(--margin);
          margin-bottom: var(--gutter);
        `}
      >
        {title}
      </div>
      <div
        className="type--20"
        css={css`
          display: flex;
          padding: 0 var(--margin) var(--margin);
          margin-bottom: var(--margin);
          overflow-x: scroll;

          &::after {
            content: '';
            display: block;
            flex: 0 0 var(--margin);
          }
        `}
        onWheel={(e) => {
          e.persist();
        }}
      >
        {programs?.length > 0 && programs
          .map((program, i) => (
            <Link
              key={i}
              css={css`
                flex: 0 0 calc(
                  (((100vw - (var(--margin) * 2)) - (var(--gutter) * 11)) / 12)
                  * 5 + (4 * var(--gutter))
                );
                text-decoration: none;
                margin-right: var(--gutter);
                overflow: hidden;

                @media (max-width: 700px) {
                  flex: 0 0 calc(
                    (((100vw - (var(--margin) * 2)) - (var(--gutter) * 11)) / 12)
                    * 10 + (9 * var(--gutter))
                  );
                }

                &:last-of-type {
                  margin-right: 0;
                }
              `}
              to={`/program/${program.slug.current}`}
            >
              {program.featureImage?.asset && <Image
                {...program.featureImage}
                width={990}
                height={620}
                style={{
                  width: '100%',
                  marginBottom: '0.15em',
                  lineHeight: 0,
                }}
                loading="eager"
              />}
              {!program.featureImage?.asset &&
                <div
                  css={css`
                    padding-bottom: 62.5%;
                    background-color: var(--primary);
                  `}
                />
              }
              <div
                css={css`
                  margin-bottom: 0.15em;

                  &::before {
                    content: '';
                    display: inline-block;
                    width: 1.1rem;
                    height: 1.1rem;
                    border-radius: 50%;
                    background-color: var(--black);
                    margin-right: 1rem;
                  }
                `}
              >
                {program.format.map(format => format.title).join('/\u200B')}
              </div>
              <div
                css={css`
                  text-transform: uppercase;
                `}
              >{program.title}</div>
            </Link>
          ))
        }
        {artists.length > 0 && artists
          .map((artist, i) => (
            <Link
              key={i}
              css={css`
                flex: 0 0 calc(
                  (((100vw - (var(--margin) * 2)) - (var(--gutter) * 11)) / 12)
                  * 5 + (4 * var(--gutter))
                );
                text-decoration: none;
                margin-right: var(--gutter);
                overflow: hidden;

                @media (max-width: 700px) {
                  flex: 0 0 calc(
                    (((100vw - (var(--margin) * 2)) - (var(--gutter) * 11)) / 12)
                    * 10 + (9 * var(--gutter))
                  );
                }

                &:last-of-type {
                  margin-right: 0;
                }
              `}
              to={`/artist/${artist.slug.current}`}
            >
              {artist.featureImage?.asset && <Image
                {...artist.featureImage}
                width={990}
                height={620}
                style={{
                  width: '100%',
                  marginBottom: '0.15em',
                  lineHeight: 0,
                }}
                loading="eager"
              />}
              {!artist.featureImage?.asset &&
                <div
                  css={css`
                    padding-bottom: 62.5%;
                    background-color: var(--primary);
                  `}
                />
              }
              <div
                css={css`
                  margin-bottom: 0.15em;

                  &::before {
                    content: '';
                    display: inline-block;
                    width: 1.1rem;
                    height: 1.1rem;
                    border-radius: 50%;
                    background-color: var(--black);
                    margin-right: 1rem;
                  }
                `}
              >{artist.type}</div>
              <div
                css={css`
                  text-transform: uppercase;
                `}
              >{artist.name}</div>
            </Link>
          ))
        }
      </div>
    </div>
  )
}
